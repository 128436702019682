// @todo:
// this utils function would be the same but bisiness logic will change based on the client
// keep this file as project specific
import { formatPrice } from '@myaccount/helper/action';
const _ = require("lodash")
const specialChar = /[^\w\s,]/gi;

/* // expected data
const sampleData = {
    "id": "action1",
    "title": "1+ Bedroom houses for sale in London between £90,0000 and £600,0000",
    "content": [
        {
            "id": "asdad",
            "title": "Location:",
            "param": "Camberwell",
        },
        {
            "id": "cvdssdf",
            "title": "Type of property:",
            "param": "House",
        },
        {
            "id": "asvxdad",
            "title": "Min Price:",
            "param": "£90000",
        },
        {
            "id": "sdgdvvc",
            "title": "Max Price:",
            "param": "£140000",
        },
        {
            "id": "cvbce",
            "title": "bedroom:",
            "param": "1 to 4",
        },
    ]
} */

const labels = {
    department: "Department",
    search_type: "Search Type",
    searchType: "Search Type",
    areas: "Location",
    building: "Type of property",
    bedroom: "Bedroom",
    minPrice: "Min Price",
    maxPrice: "Max Price",
    available: "Available",
    feature: "Features",
}

const defaultParams = {
    department: null,
    search_type: null,
    areas: null,
    building: null,
    bedroom: null,
    minPrice: null,
    maxPrice: null,
    available: null
}

const replaceSpecialChar = (str) => {
    if (typeof str === 'object')
        return str.join(', ').replace(specialChar, ' ');
    return str.replace(specialChar, ' ');
}

const searchH1 = props => {
    const params = _.merge({...defaultParams}, props)
    const { bedroom='', search_type, areas='', building='', minPrice='', maxPrice='', department, feature } = params
    let title = "",
        minimumPrice = minPrice,
        maximumPrice = maxPrice;

        // bedroom
    if (!_.isEmpty(bedroom)) {
        title += `${bedroom}+ bedroom`
        if (bedroom > 1) title += `s`
        title += ` `
    }
    if (!_.isEmpty(department)) {
        title += `${department.replace('_', ' ')} `
    }

    if (!_.isEmpty(building)) {
        // if (_.isString(building)) {
        //     title += `${replaceSpecialChar(building)} `
        // }
        // if (_.isArray(building)) {
        //     title += `${replaceSpecialChar(building)} `
        // }
    } else {
        title += `property `
    }

    if (!_.isEmpty(feature)) {
        if (_.isString(feature)) {
            title += `in ${replaceSpecialChar(feature)} `
        }
        if (_.isArray(feature)) {
            title += feature.join(` and `) + ` `;
        }
    }

    if (!_.isEmpty(search_type)) {
        if (search_type === "sales") title += `for sale `
        else if (search_type === "lettings") title += `to rent `
        else title += `for sale `
    }
    if (!_.isEmpty(areas)) {
        if (_.isString(areas)) {
            title += `in ${replaceSpecialChar(areas)} `
        }
        if (_.isArray(areas)) {
            title += areas.join(` and `) + ` `;
        }
    }

    if (minPrice && maxPrice) {
        title += `between ${formatPrice(minimumPrice)} and ${formatPrice(maximumPrice)}`
    } else if (maxPrice) {
        title += ` under ${formatPrice(maximumPrice)}`
    } else if (minPrice) {
        title += ` over ${formatPrice(minimumPrice)}`
    }

    return title.charAt(0).toUpperCase() + title.slice(1)
}

const processContent = content => {
    let newContent = []
    let excludedItems = ['area_ids', 'areas_ids', 'available'];
    let priceItems = ['minPrice', 'maxPrice'];
    let areaItems = ['areas', 'area', 'location'];
    _.mapKeys(content, function (value, key) {
        let label = labels[key]
        if (value) {
            if (!(_.indexOf(excludedItems, key) >= 0) && !key.endsWith('_ids')) {
                if ((_.indexOf(priceItems, key) >= 0)) {
                    value = formatPrice(value);
                } else if ((_.indexOf(areaItems, key) >= 0)) {
                    value = replaceSpecialChar(value);
                }
                if (typeof value === 'object') {
                    try {
                        value = value.join(', ')
                    } catch(err) {
                        console.log('error => ', err)
                        value='';
                    }
                }
                newContent.push({
                    id: key,
                    title: label,
                    param: value
                })
            }
        }
    })
    return newContent
}

const preprocess = params => {
    let data = {
        title: searchH1(params),
        content: processContent(params),
    }
    return data
}

const preProcessObjects = content => {
    let data = content.map(c => {
        if (!_.isEmpty(c)) return ({...c, ...preprocess(c.params)})
        return false;
    })

    return data
}

const isSold = (flagStatus, status) => {
    let flag = flagStatus || []
    let stat = status || []
    return (flag && flag.includes(stat))
}

export { preProcessObjects, isSold }
